import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import classnames from "classnames"
import {history} from "../history"
import PerfectScrollbar from "react-perfect-scrollbar"
import {AlertTriangle} from "react-feather"
import algoliasearch from "algoliasearch";

const searchClient = algoliasearch(
    'N75KVU87V6',
    '33b4d90958aef8b46484899b31097871'
);
const customersIndex = searchClient.initIndex("Customers");
const companiesIndex = searchClient.initIndex("Companies");


class Autocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSuggestion: 0,
            showSuggestions: false,
            userInput: "",
            focused: false,
            openUp: false,
            suggestions: [],
        };
        this.timer = null;
        this.filteredData = [];
        document.body.addEventListener("click", this.handleExternalClick)
    }

    // Suggestion Click Event
    onSuggestionItemClick = (url, e) => {
        if (this.props.onSuggestionClick) {
            this.props.onSuggestionClick(e)
        }
        this.setState({
            activeSuggestion: 0,
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });
        if (url) history.push(url)
    };

    // Suggestion Hover Event
    onSuggestionItemHover = index => {
        this.setState({activeSuggestion: index})
    };

    // Input Change
    onChange = e => {
        const userInput = e.currentTarget.value
        this.setState({
            activeSuggestion: 0,
            showSuggestions: true,
            userInput
        }, () => {
            clearTimeout(this.timer)
            this.timer = setTimeout(this.search, 750);
        });
        if (e.target.value < 1) {
            this.setState({
                showSuggestions: false
            })
        }
    };

    // Input Click Event
    onInputClick = e => {
        e.stopPropagation()
    };

    // Input's Keydown Event
    onKeyDown = e => {
        const {activeSuggestion, showSuggestions, userInput} = this.state
        const filterKey = this.props.filterKey
        let suggestionList = ReactDOM.findDOMNode(this.suggestionList)

        // User pressed the up arrow
        if (e.keyCode === 38 && activeSuggestion !== 0) {
            this.setState({activeSuggestion: activeSuggestion - 1})
            if (
                e.target.value.length > -1 &&
                suggestionList !== null &&
                activeSuggestion <= this.filteredData.length / 2
            ) {
                suggestionList.scrollTop = 0
            }
        }

        // User pressed the down arrow
        else if (
            e.keyCode === 40 &&
            activeSuggestion < this.filteredData.length - 1
        ) {
            this.setState({activeSuggestion: activeSuggestion + 1})

            if (
                e.target.value.length > -1 &&
                suggestionList !== null &&
                activeSuggestion >= this.filteredData.length / 2
            ) {
                suggestionList.scrollTop = suggestionList.scrollHeight
            }
        }

        // User Pressed ESC
        else if (e.keyCode === 27) {
            this.setState({
                showSuggestions: false,
                userInput: ""
            })
        }

        // User Pressed ENTER
        else if (e.keyCode === 13 && showSuggestions) {
            this.onSuggestionItemClick(this.filteredData[activeSuggestion].link, e)
            this.setState({
                userInput: this.filteredData[activeSuggestion][filterKey],
                showSuggestions: false
            })
        } else {
            return
        }

        // Custom Keydown Event
        if (
            this.props.onKeyDown !== undefined &&
            this.props.onKeyDown !== null &&
            this.props.onKeyDown
        ) {
            this.props.onKeyDown(e, userInput)
        }
    }

    // Grouped Suggestions
    renderGroupedSuggestion = arr => {
        const {filterKey, customRender} = this.props
        const {
            onSuggestionItemClick,
            onSuggestionItemHover,
            state: {activeSuggestion, userInput}
        } = this

        let renderSuggestion = (item, i) => {
            if (!customRender) {
                return null;
            } else if (customRender) {
                return customRender(
                    item,
                    i,
                    this.filteredData,
                    activeSuggestion,
                    onSuggestionItemClick,
                    onSuggestionItemHover,
                    userInput
                )
            } else {
                return null
            }
        }

        return arr.map((item, i) => {
            return renderSuggestion(item, i)
        })
    }

    // Renders Suggestions
    renderSuggestions = () => {
        const {filterHeaderKey,} = this.props

        this.filteredData = [];
        return this.state.suggestions.map(suggestion => {
            this.filteredData.push(...suggestion.data);
            return (
                <React.Fragment key={suggestion[filterHeaderKey]}>
                    <li className="suggestion-item suggestion-title text-primary text-bold-600">
                        {suggestion[filterHeaderKey]}
                    </li>
                    {suggestion.data.length ? (
                        this.renderGroupedSuggestion(suggestion.data)
                    ) : (
                        <li className="suggestion-item no-result">
                            <AlertTriangle size={15}/>{" "}
                            <span className="align-middle ml-50">Inga resultat</span>
                        </li>
                    )}
                </React.Fragment>
            )
        })

    }

    // Clears Input
    clearInput = val => {
        if (this.props.clearInput && !val) {
            this.setState({
                userInput: ""
            })
        }
    }

    search = async () => {
        let customersArr = {
            groupTitle: "Kunder",
            searchLimit: 10,
            data: [],
        };
        let companiesArr = {
            groupTitle: "Bolag",
            searchLimit: 10,
            data: [],
        };
        await customersIndex
            .search(this.state.userInput, {
              hitsPerPage: 5,
            })
            .then(({hits}) => {
                customersArr = {
                    ...customersArr,
                    data: hits.map(customer => {
                        const highlights = Object.values(customer._highlightResult);
                        let matchValue = customer.customerId;
                        highlights.forEach(hlight => {
                            matchValue = hlight.matchLevel === "full" ? hlight.value.replaceAll("em", "strong") : matchValue;
                        });
                        return {
                            ...customer,
                            title: customer.name,
                            link: "/customers/" + customer.objectID,
                            icon: "User",
                            id: customer.objectID,
                            match : matchValue,
                        }
                    }),
                };
            });
        await companiesIndex
            .search(this.state.userInput,{
              hitsPerPage: 5,
            })
            .then(({hits}) => {
                companiesArr = {
                    ...companiesArr,
                    data: hits.map(company => {
                        const highlights = Object.values(company._highlightResult);
                        let matchValue = company.companyId;
                        highlights.forEach(hlight => {
                            matchValue = hlight.matchLevel === "full" ? hlight.value.replaceAll("em", "strong") : matchValue;
                        });
                        return {
                            ...company,
                            title: company.name,
                            link: "/companies/" + company.objectID,
                            icon: "Briefcase",
                            id: company.objectID,
                            match : matchValue,
                        }
                    }),
                };
            });
        if (this.mounted) {
            this.setState({suggestions: [customersArr, companiesArr]});
        }

    };

    // Closes Suggestions if clicked outside container (On Blur Basically)
    handleExternalClick = e => {
        let {container} = this.refs
        const {target} = e
        if (target !== container && !container.contains(target)) {
            this.setState({
                showSuggestions: false
            })
            if (this.props.externalClick) this.props.externalClick(e)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let textInput = ReactDOM.findDOMNode(this.input)
        let {autoFocus, onSuggestionsShown, clearInput} = this.props;
        // For searchbar focus
        if (textInput !== null && autoFocus) {
            textInput.focus()
        }

        if (
            this.props.defaultSuggestions &&
            prevState.showSuggestions === false &&
            this.state.focused
        ) {
            this.setState({showSuggestions: true})
        }

        if (!clearInput && this.state.userInput &&this.state.userInput.length) {
            this.setState({
                userInput: ""
            });
        }

        // Function on Suggestions Shown
        if (onSuggestionsShown && this.state.showSuggestions) {
            onSuggestionsShown(this.state.userInput)
        }

        if (
            this.props.defaultSuggestions &&
            prevState.focused === false &&
            this.state.focused === true
        ) {
            this.setState({showSuggestions: true})
        }
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.defaultSuggestions && this.state.focused) {
            this.setState({showSuggestions: true})
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this.handleExternalClick)
        clearTimeout(this.timer)
        this.mounted = false;
    }

    render() {
        const {
            onChange,
            onKeyDown,
            state: {showSuggestions, userInput, openUp}
        } = this
        let suggestionsListComponent;

        if (showSuggestions) {
            suggestionsListComponent = (
                <PerfectScrollbar
                    className={classnames("suggestions-list", {
                        "open-up": openUp
                    })}
                    ref={el => (this.suggestionList = el)}
                    component="ul"
                    options={{wheelPropagation: false}}>
                    {this.renderSuggestions()}
                </PerfectScrollbar>
            )
        }

        return (
            <div className="vx-autocomplete-container" ref="container">
                <input
                    type="text"
                    onChange={e => {
                        onChange(e);
                        if (this.props.onChange) {
                            this.props.onChange(e)
                        }
                    }}
                    onKeyDown={e => onKeyDown(e)}
                    value={userInput ? userInput : ""}
                    className={`vx-autocomplete-search ${
                        this.props.className ? this.props.className : ""
                    }`}
                    placeholder={this.props.placeholder}
                    onClick={this.onInputClick}
                    ref={el => {
                        return (this.input = el)
                    }}
                    onFocus={e => {
                        this.setState({focused: true})
                    }}
                    autoFocus={this.props.autoFocus}
                    onBlur={e => {
                        // this.onBlur(e)
                        if (this.props.onBlur) this.props.onBlur(e)
                        this.setState({focused: false})
                    }}
                />
                {suggestionsListComponent}
            </div>
        )
    }
}

export default Autocomplete

Autocomplete.propTypes = {
    filterKey: PropTypes.string.isRequired,
    filterHeaderKey: PropTypes.string,
    placeholder: PropTypes.string,
    suggestionLimit: PropTypes.number,
    grouped: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    onSuggestionsShown: PropTypes.func,
    onSuggestionItemClick: PropTypes.func
}
