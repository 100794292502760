import React from "react"
import VerticalLayout from "../../layouts/VerticalLayout"
import FullLayout from "../../layouts/FullpageLayout"
import themeConfig from "../../configs/themeConfig"

const layouts = {
    vertical: VerticalLayout,
    full: FullLayout,
};

const ContextLayout = React.createContext();

class Layout extends React.Component {
    state = {
        activeLayout: themeConfig.layout,
        width: window.innerWidth,
    };

    updateWidth = () => {
        this.setState({
            width: window.innerWidth
        })
    };

    handleWindowResize = () => {
        this.updateWidth()
    };

    componentDidMount = () => {
        if (window !== "undefined") {
            window.addEventListener("resize", this.handleWindowResize)
        }
    };



    render() {
        const {children} = this.props;
        return (
            <ContextLayout.Provider
                value={{
                    state: this.state,
                    fullLayout: layouts["full"],
                    verticalLayout: layouts["vertical"],
                }}
            >
                {children}
            </ContextLayout.Provider>
        )
    }
}

export {Layout, ContextLayout}
