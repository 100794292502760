import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "Meny"
  },
  {
    id: "dashboard",
    title: "Hem",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin"],
    navLink: "/dashboard",
  },
  {
    id: "my-customers",
    title: "Mina Kunder",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin"],
    navLink: "/my-customers/:customerNumber",
    filterBase: "/my-customers/broker-list"
  },
  {
    id: "customers",
    title: "Alla Kunder",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin"],
    navLink: "/customers/:customerNumber",
    filterBase: "/customers/list"
  },
  {
    id: "companies",
    title: "Bolag",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: ["admin"],
    navLink: "/companies/:companyNumber",
    filterBase: "/companies/list"
  },
  {
    id: "todo",
    title: "Todo",
    type: "item",
    icon: <Icon.CheckCircle size={20} />,
    permissions: ["admin"],
    navLink: "/todo"
  },
  {
    id: "chatt",
    title: "Chatt",
    type: "item",
    icon: <Icon.Send size={20} />,
    permissions: ["admin"],
    navLink: "/chat"
  },
  {
    id: "blanketter",
    title: "Blanketter",
    type: "item",
    icon: <Icon.Clipboard size={20} />,
    permissions: ["admin"],
    navLink: "/forms"
  },
  {
    id: "statistik",
    title: "Statistik",
    type: "item",
    icon: <Icon.Activity size={20} />,
    permissions: ["admin"],
    navLink: "/statistics"
  },
  {
    id: "import",
    title: "Importera",
    type: "item",
    icon: <Icon.UploadCloud size={20} />,
    permissions: ["admin"],
    navLink: "/import"
  },
  {
    type: "groupHeader",
    groupTitle: "" // Leave empty to allow for last tab opacity
  },
];

export default navigationConfig
