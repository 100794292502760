import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"
import {signOut} from "../../../services/AuthServices";


const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" onClick={() => history.push('/account-settings')}>
        <Icon.Lock size={14} className="mr-50" />
        <span className="align-middle">Inställningar</span>
      </DropdownItem>
        <DropdownItem tag="a" href="#" onClick={() => history.push("/auth/lock")}>
            <Icon.User size={14} className="mr-50" />
            <span className="align-middle">Lås Skärm</span>
        </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="#"
        onClick={() => signOut(true, true)}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Logga ut</span>
      </DropdownItem>
    </DropdownMenu>
  )
};

const AddDropdown = props => {
  return (
      <DropdownMenu right>
        <DropdownItem tag="a" href="#">
          <Icon.Users size={14} className="mr-50" />
          <span className="align-middle">Lägg till kund</span>
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <Icon.TrendingUp size={14} className="mr-50" />
          <span className="align-middle">Lägg till aktie</span>
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <Icon.BookOpen size={14} className="mr-50" />
          <span className="align-middle">Lägg till order</span>
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <Icon.PhoneOutgoing size={14} className="mr-50" />
          <span className="align-middle">Lägg till lead</span>
        </DropdownItem>
          {/*<DropdownItem divider />
        <DropdownItem tag="a" href="#">
          <Icon.UserPlus size={14} className="mr-50" />
          <span className="align-middle">Lägg till användare</span>
        </DropdownItem>*/}
      </DropdownMenu>
  )
};

class NavbarUser extends React.PureComponent {

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
          {/*<UncontrolledDropdown tag="li" className="nav-item">
          <DropdownToggle tag="a" className="nav-link">
            <Icon.PlusCircle size={21} data-tour="search" />
          </DropdownToggle>
          <AddDropdown {...this.props} />
        </UncontrolledDropdown>*/}
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span style={{marginTop : 10}} className="user-status">Online</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="45"
                width="45"
                alt="User"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser
