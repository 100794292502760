import React, { useEffect, useState } from "react"
import ScrollToTop from "react-scroll-up"
import { Button } from "reactstrap"
import { ArrowUp } from "react-feather"
import classnames from "classnames"
import moment from "moment";
import 'moment/locale/sv';


const Footer = props => {
  moment.locale('sv');
  const [time, setTime] = useState(moment().format('llll'));
  let footerTypeArr = ["sticky", "static", "hidden"];


  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format('llll'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
      <footer
          className={classnames("footer footer-light", {
            "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
            "d-none": props.footerType === "hidden"
          })}
      >
        <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          <span className="align-middle">Copyright © {new Date().getFullYear()} Monitor Capital Markets</span>
        </span>
          <span className="float-md-right d-none d-md-block">
          <span className="align-middle">{time}</span>
        </span>
        </p>
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      </footer>
  )
};

export default Footer
