import firebase from "firebase/app";
import "firebase/auth";
import "firebase/auth";
import { toast } from "react-toastify";
import { history } from "../history";

export const submitLogin = (email, password) => {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((result) => {
      /*
                        firebase
                            .auth()
                            .setPersistence(firebase.auth.Auth.Persistence.SESSION);
             */
      //firebase.firestore().collection("Users").doc(result.user.uid).update({status : "Online"});
      toast.success("Välkommen tillbaka!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    })
    .catch((error) => {
      error.code === "auth/wrong-password"
        ? toast.error("Felaktigt lösenord", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          })
        : toast.error(
            "Något gick snett. Kontrollera uppgifterna och försök igen!",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
    });
};

export const signOut = (shouldRedirect, showByeMessage) => {
  const uid = firebase.auth().currentUser.uid;
  firebase
    .firestore()
    .collection("Users")
    .doc(uid)
    .update({ status: "Offline" })
    .then(() => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          if (shouldRedirect) {
            history.push("/auth/login");
          }
          if (showByeMessage) {
            toast.success("Vi ses snart igen!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2500,
            });
          }
        })
        .catch((err) => {
          toast.error("Något gick snett", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    })
    .catch((error) => {
      toast.error("Något gick snett", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
};

export const isAuthenticated = () => {
  return firebase.auth().currentUser !== null;
};
