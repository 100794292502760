import React from "react"
import {
    NavItem,
    NavLink,
} from "reactstrap"
import * as Icon from "react-feather"
import classnames from "classnames"
import AutoComplete from "../../../components/AutoCompleteComponent"
import "firebase/firestore";

export default class NavbarSearch extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            navbarSearch: false,
            //suggestions: [],
            windowWidth: window.innerWidth,
        };
        this.mounted = true;
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this.mounted = false;
    }


    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth});
    };

    handleNavbarSearch = () => {
        this.setState({
            navbarSearch: !this.state.navbarSearch
        });
    };

    render() {

        const {
            sidebarVisibility,
        } = this.props;
        const {windowWidth} = this.state;

        return (
            <div style={{
                cursor: "pointer",
            }} className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                <ul className="navbar-nav d-xl-none">
                    <NavItem className="mobile-menu mr-auto">
                        <NavLink
                            className="nav-menu-main menu-toggle hidden-xs is-active"
                            onClick={sidebarVisibility}>
                            <Icon.Menu className="ficon"/>
                        </NavLink>
                    </NavItem>
                </ul>
                <ul className="nav navbar-nav bookmark-icons"
                    style={{paddingRight: windowWidth / 3, /*display: windowWidth > 1000 ? "block" : "none"*/}}
                    onClick={this.handleNavbarSearch}>
                    <NavItem className="nav-search">
                        <NavLink className="nav-link-search">
                            <Icon.Search size={21} data-tour="search"/>
                        </NavLink>
                        <div
                            className={classnames("search-input", {
                                open: this.state.navbarSearch,
                                "d-none": this.state.navbarSearch === false
                            })}
                        >
                            <div className="search-input-icon">
                                <Icon.Search size={17} className="primary"/>
                            </div>
                            <AutoComplete
                                className="form-control"
                                filterKey="target"
                                filterHeaderKey="groupTitle"
                                placeholder="Sök i systemet..."
                                autoFocus={true}
                                clearInput={this.state.navbarSearch}
                                externalClick={e => {
                                    this.setState({navbarSearch: false})
                                }}
                                onKeyDown={e => {
                                    if (e.keyCode === 27 || e.keyCode === 13) {
                                        this.setState({
                                            navbarSearch: false
                                        });
                                        this.props.handleAppOverlay("")
                                    }
                                }}
                                customRender={(
                                    item,
                                    i,
                                    filteredData,
                                    activeSuggestion,
                                    onSuggestionItemClick,
                                    onSuggestionItemHover
                                ) => {
                                    const IconTag = Icon[item.icon ? item.icon : "X"];
                                    return (
                                        <li
                                            className={classnames("suggestion-item", {
                                                active: filteredData.indexOf(item) === activeSuggestion
                                            })}
                                            key={i}
                                            onClick={e => onSuggestionItemClick(item.link, e)}
                                            onMouseEnter={() =>
                                                onSuggestionItemHover(filteredData.indexOf(item))
                                            }
                                        >
                                            <div
                                                className={classnames({
                                                    "d-flex justify-content-between align-items-center":
                                                        item.file || item.img
                                                })}
                                            >
                                                <div className="item-container d-flex">
                                                    {item.icon ? (
                                                        <IconTag size={17}/>
                                                    ) : item.file ? (
                                                        <img
                                                            src={item.file}
                                                            height="36"
                                                            width="28"
                                                            alt={item.title}
                                                        />
                                                    ) : item.img ? (
                                                        <img
                                                            className="rounded-circle mt-25"
                                                            src={item.img}
                                                            height="28"
                                                            width="28"
                                                            alt={item.title}
                                                        />
                                                    ) : null}
                                                    <div className="item-info ml-1">
                                                        <p className="align-middle mb-0">{item.title}</p>
                                                            <small>
                                                                <div dangerouslySetInnerHTML={{__html: item.match}} />
                                                            </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }}
                                onSuggestionsShown={userInput => {
                                    if (this.state.navbarSearch) {
                                        this.props.handleAppOverlay(userInput)
                                    }
                                }}
                            />
                            <div className="search-input-close">
                                <Icon.X
                                    size={24}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                            navbarSearch: false
                                        });
                                        this.props.handleAppOverlay("")
                                    }}
                                />
                            </div>
                        </div>
                    </NavItem>
                </ul>
            </div>
        )
    }
}
