import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import Spinner from "./components/Fallback-spinner";
import "./index.scss";

const LazyApp = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./App")), 500);
  });
});

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <Layout>
        <LazyApp />
      </Layout>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
